<i18n>
{
  "de": {
    "pageTitle": "Lebensdauern",
    "settings": "Einstellungen",
    "updateParamsPendingMessage": "Parameter werden gespeichert...",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "returnButton": "Zurück"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>
    </template>

    <template #default>
      <EditLifespans :portfolio="portfolio" />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import EditLifespans from '@/components/settings/EditLifespans.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

export default {
  name: 'settingsLifespans',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    EditLifespans,
    PageTitle,
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
